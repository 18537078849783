.masonry-grid {
  display: flex;
  margin-left: -64px;
  width: auto;

  @media(max-width: 600px) {
    margin-left: -16px;
  }

  .masonry-grid-column {
    padding-left: 64px;
    background-clip: padding-box;

    @media(max-width: 600px) {
      padding-left: 16px;
    }

    .masonry-item {
      margin-bottom: 64px;
      overflow: hidden;
      border-radius: 8px;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
      transition: transform 0.3s, box-shadow 0.3s;

      @media(max-width: 600px) {
        margin-bottom: 16px;
      }

      img, video {
        display: block;
        width: 100%;
        height: auto;
        border-radius: 8px;

        filter: grayscale(50%);

        &:hover {
          filter: none;
        }
      }

      video {
        display: none;
      }

      &:hover {
        transform: scale(1.05);
        box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);

        .thumbnail {
          display: none;
        }

        video {
          display: block;
        }
      }
    }
  }
}