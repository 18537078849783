@use '../../index' as index;

$image-sides: 200px;

.projects-header {
  text-align: center;
  margin-bottom: index.$base-spacing*5;

  p {
    font-size: 1.5em;

    @media(max-width: 600px), (max-height: 600px) {
      font-size: 1em;
    }
  }
}

.projects {
  display: flex;
  flex-direction: column;
  gap: index.$base-spacing*4;

  .project {
    display: flex;
    align-items: center;
    justify-content: space-between;

    border: 4px solid index.$primary-dark-color;
    border-radius: 8px;
    padding: index.$base-spacing*2;

    @media(max-width: 600px) {
      flex-direction: column;
    }

    .title {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;

      font-size: 24px;
      font-weight: bold;
      margin-bottom: index.$base-spacing*5;

      @media(max-width: 600px) {
        margin-bottom: index.$base-spacing;
      }

      & .code-link {
        height: 24px;
        width: 24px;

        margin-right: index.$base-spacing*2;
        border-radius: 20px;
        padding: index.$base-spacing;

        background-color: index.$text-color;
        color: index.$primary-light-color;

        cursor: pointer;
        box-shadow: 4px 4px 4px #999;

        &:hover,
        &:active {
          color: index.$text-color;
          background-color: index.$primary-light-color;
        }
      }
    }

    .content {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: index.$base-spacing*2;
      width: calc(100% - #{$image-sides});

      @media(max-width: 600px) {
        width: auto;
      }

      .text {
        .description {
          text-indent: 2em;
          margin-bottom: index.$base-spacing*3;

          a {
            color: index.$primary-dark-color;
          }
        }

        .tech {
          text-align: center;
        }
      }
    }

    .image-container {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;

      height: $image-sides + 20px;
      width: $image-sides + 20px;

      .image-link {
        position: absolute;

        border: 2px solid index.$primary-dark-color;
        border-radius: 4px;
        flex-grow: 500;

        height: $image-sides;
        width: $image-sides;

        &.link {
          cursor: pointer;
          box-shadow: 4px 4px 4px #999;

          transition: width 2s, height 2s;

          &:hover,
          &:active {
            border-color: index.$primary-light-color;
            height: $image-sides + 20px;
            width: $image-sides + 20px;
          }
        }

        & img {
          object-fit: cover;
          height: 100%;
          width: 100%;
        }
      }
    }
  }
}