$base-spacing: 8px;
$text-color: #243447;
$primary-dark-color: $text-color;
$primary-light-color: #395B64;
$background-color: #E7F6F6;

body {
  margin: 0;
  font-family: Akshar, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background-color: $background-color;
  color: $text-color;

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
  }

  box-sizing: border-box;
}



