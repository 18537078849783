@use '../../index' as index;

.bio-content {
  .header {
    font-size: 2em;
  }

  p {
    font-size: 1.5em;

    @media( max-width: 600px), (max-height: 600px) {
      font-size: 1em;
    }

    a {
      color: index.$primary-dark-color;
    }
  }
}