@use '../../index.scss' as index;

$header-height: 56px;

.nav {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: index.$base-spacing;
  padding: index.$base-spacing*5 index.$base-spacing;

  @media (max-width: 600px), (max-height: 600px) {
    padding: index.$base-spacing*2 index.$base-spacing*0.5;
    gap: index.$base-spacing*0.5;
  }

  position: fixed;

  inset: 0 auto 0 0;

  background-color: index.$primary-light-color;

  .divider {
    width: 80%;
    height: 2px;
    background-color: index.$text-color;
    margin: index.$base-spacing 0;

    @media (max-width: 600px), (max-height: 600px) {
      margin: index.$base-spacing*0.5 0;
    }
  }

  .navigation {
    display: flex;
    flex-direction: column;
    gap: index.$base-spacing;
    align-items: center;

    @media (max-width: 600px), (max-height: 600px) {
      gap: index.$base-spacing*0.5;
    }

    & a, .icon-container {
      display: inline-block;
      text-decoration: none;
      color: index.$text-color;

      &:hover {
        color: index.$background-color;
        background-color: index.$text-color;
        box-shadow: 2px 2px 4px #444;
      }
    }

    &.top {
      gap: index.$base-spacing*2;

      @media (max-width: 600px), (max-height: 600px) {
        gap: index.$base-spacing;
      }

      & a {
        font-size: 32px;

        @media (max-width: 600px), (max-height: 600px) {
          font-size: 20px;
          gap: index.$base-spacing*0.5;
        }
      }

      .icon-container {
        font-size: 32px;
        padding: index.$base-spacing;
        border-radius: 4px;
        text-decoration: none;

        cursor: pointer;

        @media(max-width: 600px), (max-height: 600px) {
          font-size: 20px;
        }
      }
    }

    &.bottom {
      & a {
        margin: 0;
        padding: index.$base-spacing*1.5;
        border-radius: 4px;

        font-size: 20px;
        font-weight: bold;

        cursor: pointer;

        @media(max-width: 600px), (max-height: 600px) {
          font-size: 16px;
          padding: index.$base-spacing;
        }
      }
    }
  }
}

.main-content {
  padding: index.$base-spacing*4;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 125px;
  right: 0;

  overflow-y: scroll;

  @media(max-width: 600px), (max-height: 600px) {
    padding: index.$base-spacing;
  }
}
